<template>
  <div class="container" v-loading="loading" element-loading-text="数据导出中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="关键词">
          <el-input v-model="keyword" autocomplete="off" size="small" placeholder="请输入关键词" clearable></el-input>
        </el-form-item>
        <el-form-item label="绑定状态">
          <el-select v-model="bindStatus" placeholder="请选择绑定状态" clearable size="small">
            <el-option v-for="(item,i) in [{text:'已绑定',id:1},{text:'未绑定',id:0}]" :key="i" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行政区域">
          <el-cascader :disabled="isLoad" v-model="areaCodes" size="small" placeholder="请选择行政区域" :options="areaList" :props="{ checkStrictly: true }" clearable style="width:180px"></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" icon="el-icon-search" @click="initTable">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="small" icon="el-icon-plus" @click="open('add')">新增水源</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" size="small" icon="el-icon-download" @click="exportExcel">导出水源</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="waterList" stripe style="width: 100%" height="100%" v-loading="loading1">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-row :gutter="20">
              <el-col :span="12" :offset="0">
                <el-form label-position="left" inline class="demo-table-expand" label-width="170px">
                  <el-form-item label="消防栓编号或水源名称">
                    <span>{{props.row.code}}</span>
                  </el-form-item>
                  <el-form-item label="地址">
                    <span>{{props.row.location}}</span>
                  </el-form-item>
                  <el-form-item label="管网直径">
                    <span>{{props.row.width}}</span>
                  </el-form-item>
                  <el-form-item label="供水单位">
                    <span>{{props.row.waterUnitName}}</span>
                  </el-form-item>
                  <el-form-item label="经纬度">
                    <span>{{props.row.lng}} # {{props.row.lat}}</span>
                    <el-button type="text" size="default" @click="open('location',{lng:props.row.lng,lat:props.row.lat})" icon="el-icon-location"></el-button>
                  </el-form-item>
                  <el-form-item label="管网压力">
                    <span>{{props.row.pressure}}</span>
                  </el-form-item>
                  <el-form-item label="状态">
                    <el-tag type="success" size="normal" v-show="props.row.status===0">良好</el-tag>
                    <el-tag type="warning" size="normal" v-show="props.row.status===1">不可用</el-tag>
                    <el-tag type="danger" size="normal" v-show="props.row.status===2">损坏</el-tag>
                  </el-form-item>
                  <el-form-item label="东面">
                    <span>{{props.row.east}}</span>
                  </el-form-item>
                  <el-form-item label="南面">
                    <span>{{props.row.south}}</span>
                  </el-form-item>
                  <el-form-item label="图片">
                    <el-image :src="props.row.imageUrl" fit="fill" :lazy="true" :preview-src-list="[props.row.imageUrl]"></el-image>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :span="12" :offset="0">
                <el-form label-position="left" inline class="demo-table-expand">
                  <el-form-item label="所属区域" :label-width="formLabelWidth">
                    <span>{{props.row.areaName}}</span>
                  </el-form-item>
                  <el-form-item label="水源类型" :label-width="formLabelWidth">
                    <span>{{props.row.waterType}}</span>
                  </el-form-item>
                  <el-form-item label="管网形式" :label-width="formLabelWidth">
                    <el-tag type="primary" size="size">{{props.row.pipeType===0?'枝状':'环状'}}</el-tag>
                  </el-form-item>
                  <el-form-item label="消防部门" :label-width="formLabelWidth">
                    <span>{{props.row.fireUnitName}}</span>
                  </el-form-item>
                  <el-form-item label="形式" :label-width="formLabelWidth">
                    <el-tag type="primary" size="size">{{props.row.type===0?'地上':'地下'}}</el-tag>
                  </el-form-item>
                  <el-form-item label="联系电话" :label-width="formLabelWidth">
                    <span>{{props.row.contact}}</span>
                  </el-form-item>
                  <el-form-item label="负责人" :label-width="formLabelWidth">
                    <span>{{props.row.head}}</span>
                  </el-form-item>
                  <el-form-item label="西面" :label-width="formLabelWidth">
                    <span>{{props.row.west}}</span>
                  </el-form-item>
                  <el-form-item label="北面" :label-width="formLabelWidth">
                    <span>{{props.row.north}}</span>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="消防栓编号/水源名称" show-overflow-tooltip width="180">
        </el-table-column>
        <el-table-column prop="location" label="地址" show-overflow-tooltip width="150">
        </el-table-column>
        <el-table-column prop="pipeType" label="管网形式" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag type="primary" size="size">{{scope.row.pipeType===0?'枝状':'环状'}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="形式" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag type="primary" size="size">{{scope.row.type===0?'地上':'地下'}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="pressure" label="管网压力" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.pressure}}Mpa
          </template>
        </el-table-column>
        <el-table-column prop="width" label="管网直径" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.width}}mm
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag type="primary" size="small" v-show="scope.row.status===0">良好</el-tag>
            <el-tag type="primary" size="small" v-show="scope.row.status===1">不可用</el-tag>
            <el-tag type="primary" size="small" v-show="scope.row.status===2">损坏</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="经纬度" width="150">
          <template slot-scope="scope">
            <span>{{scope.row.lng.toFixed(3)}}#{{scope.row.lat.toFixed(3)}}</span>
            <el-button type="text" icon="el-icon-location" size="default" @click="open('location',{lng:scope.row.lng,lat:scope.row.lat})"></el-button>
          </template>
        </el-table-column>
        <el-table-column label="绑定设备" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="default" @click="open('info',scope.row)" v-if="scope.row.equipmentId">查看设备</el-button>
            <el-button type="text" size="default" v-if="!scope.row.equipmentId" style="color:#F56C6C">暂未绑定</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="修改" placement="top">
              <el-button size="mini" @click="open('edit',scope.row)" type="warning" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button size="mini" @click="delWater(scope.row.id)" type="danger" icon="el-icon-delete-solid" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="绑定设备" placement="top">
              <el-button size="mini" @click="open('bind',scope.row)" type="primary" icon="el-icon-lock" circle v-if="!scope.row.equipmentId"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="解除绑定" placement="top">
              <el-button size="mini" @click="unbind(scope.row.id)" type="danger" icon="el-icon-unlock" circle v-if="scope.row.equipmentId"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 水源表单弹窗 -->
    <el-dialog :title="optFlag?'添加水源':'修改水源'" :visible.sync="waterShow" width="55%" @close="clear" top="3%" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="water" ref="waterForm" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="消防栓编号或水源名称" prop="code" label-width="170px">
              <el-input v-model="water.code" autocomplete="off" placeholder="请输入消防栓编号或水源名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="行政区域" :label-width="formLabelWidth">
              <el-cascader :disabled="isLoad" v-model="water.areaCodes" placeholder="请选择行政区域" :options="areaList" :props="{ checkStrictly: true }" clearable style="width:100%"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="水源地址" prop="location" :label-width="formLabelWidth2">
              <el-input v-model="water.location" autocomplete="off" placeholder="请输入水源地址"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="水源类型" prop="waterTypeId" :label-width="formLabelWidth">
              <el-select v-model="water.waterTypeId" placeholder="请选择水源类型" clearable style="float:left">
                <el-option v-for="(item,i) in waterTypeList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="管网直径" prop="width" :label-width="formLabelWidth2">
              <el-input v-model="water.width" autocomplete="off" placeholder="请输入管网直径"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="管网形式" prop="pipeType" :label-width="formLabelWidth">
              <el-select v-model="water.pipeType" placeholder="请选择水源类型" clearable style="float:left">
                <el-option v-for="(item,i) in [{typeName:'枝状',id:0},{typeName:'环状',id:1}]" :key="i" :label="item.typeName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="供水单位" :label-width="formLabelWidth2" prop="waterUnit">
              <!-- <el-autocomplete class="inline-input" v-model="water.unitName" value-key="unitName" :fetch-suggestions="querySearch" placeholder="请输入所在单位" :trigger-on-focus="false" @select="handleSelect"></el-autocomplete> -->
              <el-select v-model="water.waterUnit" ref="selectUnit" placeholder="请选择所在单位" clearable filterable remote :remote-method="remoteMethod" style="float:left">
                <el-option v-for="(item,i) in unitOptions" :key="i" :label="item.unitName" :value="item.id">
                  <span style="float: left">{{ item.unitName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{ item.address }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="消防部门" prop="fireUnitId" :label-width="formLabelWidth">
              <el-select v-model="water.fireUnitId" placeholder="请选择所在消防部门" clearable filterable style="float:left">
                <el-option v-for="item in fireUnitList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="经纬度" :label-width="formLabelWidth2">
              <el-row :gutter="20">
                <el-col :span="9" :offset="0">
                  <el-form-item prop="lng">
                    <el-input clearable v-model.number="water.lng" autocomplete="off" placeholder="经度" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="1" :offset="0">
                  #
                </el-col>
                <el-col :span="9" :offset="0">
                  <el-form-item prop="lat">
                    <el-input clearable v-model.number="water.lat" autocomplete="off" placeholder="纬度" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2" :offset="0" style="padding-left:0">
                  <el-form-item>
                    <el-tooltip class="item" effect="dark" content="定位" placement="top">
                      <el-button type="text" style="font-size:16px" size="default" @click="open('map')" icon="el-icon-location"></el-button>
                    </el-tooltip>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="联系电话" prop="contact" :label-width="formLabelWidth">
              <el-input v-model="water.contact" autocomplete="off" placeholder="请输入联系电话"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="管网压力" prop="pressure" :label-width="formLabelWidth2">
              <el-input v-model="water.pressure" autocomplete="off" placeholder="请输入管网压力"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="形式" prop="type" :label-width="formLabelWidth">
              <el-select v-model="water.type" placeholder="请选择形式" clearable style="float:left">
                <el-option v-for="(item,i) in [{typeName:'地上',id:0},{typeName:'地下',id:1}]" :key="i" :label="item.typeName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="状态" prop="status" :label-width="formLabelWidth2">
              <el-select v-model="water.status" placeholder="请选择状态" clearable style="float:left">
                <el-option v-for="(item,i) in [{typeName:'良好',id:0},{typeName:'不可用',id:1},{typeName:'损坏',id:2}]" :key="i" :label="item.typeName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="负责人" prop="head" :label-width="formLabelWidth">
              <el-input v-model="water.head" autocomplete="off" placeholder="请输入负责人"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="东面" prop="east" :label-width="formLabelWidth2">
              <el-input v-model="water.east" autocomplete="off" placeholder="请输入东面"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="西面" prop="west" :label-width="formLabelWidth">
              <el-input v-model="water.west" autocomplete="off" placeholder="请输入西面"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="南面" prop="south" :label-width="formLabelWidth2">
              <el-input v-model="water.south" autocomplete="off" placeholder="请输入南面"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="北面" prop="north" :label-width="formLabelWidth">
              <el-input v-model="water.north" autocomplete="off" placeholder="请输入北面"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20" :offset="0">
            <el-form-item label="水源照片" prop="imageUrl" :label-width="formLabelWidth2">
              <el-upload ref="upload" :file-list="fileList" :headers="{Authorization:token}" action="http://jaxf.jjxsw.vip:9002/app-version/uploadFile" :on-success="getWaterImage" :on-error="error" :limit="1" list-type="picture-card" :on-preview="handlePictureCardPreview">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog title="水源照片" :visible.sync="dialogVisible" :append-to-body="true" top="0">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="waterShow = false">取 消</el-button>
        <el-button type="primary" @click="addWater" v-if="optFlag">添 加</el-button>
        <el-button type="primary" @click="updateWater" v-if="!optFlag">修 改</el-button>
      </div>
    </el-dialog>
    <!-- 地图 -->
    <el-dialog class="dialog" title="地图" :visible.sync="mapShow" width="50%" top="5%" :close-on-click-modal="false">
      <div class="map">
        <baidu-map class="bmView" :scroll-wheel-zoom="true" :center="location" style="height:500px" :zoom="zoom" ak="ahVb0clSVLITXPpNieRI7DXL47tgHvcE" @ready="handler" @click="setPosition">
          <bm-view style="width: 100%; height:100px; flex: 1"></bm-view>
          <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
          <bm-marker :position="location" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
          </bm-marker>
        </baidu-map>
      </div>
      <div class="dialog-footer" v-show="optFlag">
        <el-button @click="mapShow = false">取 消</el-button>
        <el-button type="primary" @click="getPosition">确 定</el-button>
      </div>
    </el-dialog>
    <!-- NB水压列表 -->
    <el-dialog title="绑定设备" :visible.sync="bindShow" width="40%" top="3%">
      <div class="header">
        <el-form ref="bindForm" :rules="rules" :inline="true" size="small">
          <el-form-item>
            <el-input v-model="equipmentCode" placeholder="请输入设备特征码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getDevice">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="content">
        <el-table :data="deviceList" height="400" highlight-current-row @current-change="chooseRow">
          <el-table-column prop="address" label="地址" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="lastContactTime" label="状态" show-overflow-tooltip width="80">
            <template slot-scope="scope">
              <el-tag type="success" size="normal" v-show="(new Date().getTime()-new Date(scope.row.lastContactTime).getTime())<72*60*60*1000">在线</el-tag>
              <el-tag type="danger" size="normal" v-show="(new Date().getTime()-new Date(scope.row.lastContactTime).getTime())>72*60*60*1000">离线</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="unitName" label="所在单位" show-overflow-tooltip>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <el-pagination @current-change="handleDeviceChange" :current-page.sync="devicePage" :page-size="10" layout="total, prev, pager, next, jumper" :total="deviceTotal">
        </el-pagination>
      </div>
      <span>
        <el-button @click="bindShow = false">取消</el-button>
        <el-button type="primary" @click="bindDevice">绑定</el-button>
      </span>
    </el-dialog>
    <el-drawer title="NB水压" :visible.sync="infoShow" direction="rtl" size="30%" :before-close="handleClose">
      <el-form label-position="left" inline class="drawer-form" v-loading="deviceLoading">
        <el-form-item label="设备类型" :label-width="formLabelWidth">
          <span>{{device.typeName}}</span>
        </el-form-item>
        <el-form-item label="数据" :label-width="formLabelWidth">
          <el-tag size="normal" v-show="device.jcqValue">{{parseInt(device.jcqValue)*0.1}}Mpa</el-tag>
        </el-form-item>
        <el-form-item label="所在单位" :label-width="formLabelWidth">
          <span>{{device.unitName}}</span>
        </el-form-item>
        <el-form-item label="安装建筑物" :label-width="formLabelWidth">
          <span>{{device.installName}}</span>
        </el-form-item>
        <el-form-item label="地址" :label-width="formLabelWidth">
          <span>{{device.address}}</span>
        </el-form-item>
        <el-form-item label="联系电话" :label-width="formLabelWidth">
          <span>{{device.contactPhone}}</span>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-tag type="success" size="normal" v-show="device.status===1">在线</el-tag>
          <el-tag type="danger" size="normal" v-show="device.status===0">离线</el-tag>
        </el-form-item>
        <el-form-item label="安装时间" :label-width="formLabelWidth">
          <span>{{device.installTime|timeFormat}}</span>
        </el-form-item>
        <el-form-item label="设备特征码" :label-width="formLabelWidth">
          <span>{{device.equipmentCode}}</span>
        </el-form-item>
        <el-form-item label="基站特征码" :label-width="formLabelWidth">
          <span>{{device.stationCode}}</span>
        </el-form-item>
        <el-form-item label="最近数据通讯时间" :label-width="formLabelWidth">
          <span>{{device.lastContactTime|timeFormat}}</span>
        </el-form-item>
        <el-form-item label="描述" :label-width="formLabelWidth">
          <span>{{device.description}}</span>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      device: {}, // NB水压数据
      location: {
        lng: 0,
        lat: 0
      },
      zoom: 15,
      addressKeyword: '',
      currentPage: 1,
      devicePage: 1,
      formLabelWidth: '130px',
      formLabelWidth2: '120px',
      total: 0,
      deviceTotal: 0,
      waterList: [],
      deviceList: [],
      waterTypeList: [],
      areaList: [],
      waterId: 0, // 单位ID
      typeList: [],
      fireUnitList: [],
      water: {
        lng: '',
        lat: ''
      },
      waterCopy: {
        lng: '',
        lat: ''
      },
      rules: {
        waterTypeId: [
          { required: true, message: '请选择水源类型', trigger: 'change' }
        ],
        fireUnitId: [
          { required: true, message: '请选择消防部门', trigger: 'change' }
        ],
        code: [
          { required: true, message: '请输入消防栓编号或水源名称', trigger: 'blur' }
        ],
        location: [
          { required: true, message: '请输入水源地址', trigger: 'blur' }
        ]
      },
      searchForm: {
      },
      waterShow: false,
      dialogVisible: false,
      mapShow: false,
      bindShow: false,
      infoShow: false,
      optFlag: false,
      isLoad: false,
      unitOptions: [],
      buildingOptions: [],
      areaCodes: [],
      fileList: [],
      loading: false,
      loading1: false,
      deviceLoading: false,
      keyword: '',
      bindStatus: '',
      equipmentCode: '',
      checkEquipmentCode: '',
      token: '',
      dialogImageUrl: ''
    }
  },
  created () {
    this.initTable()
    this.getWaterTypeList()
    this.getAreaList()
    this.getFireUnitList()
    this.waterCopy = JSON.stringify(this.water)
    this.token = sessionStorage.getItem('token')
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    async handleDeviceChange (val) {
      this.devicePage = val
      this.getDevice()
    },
    // 表格初始化
    async initTable () {
      const data = {}
      if (this.keyword) {
        data.keyword = this.keyword
      }
      if (this.areaCodes.length > 0) {
        data.areaCode = this.areaCodes[this.areaCodes.length - 1]
      }
      data.bindStatus = this.bindStatus
      data.pageNo = this.currentPage
      data.pageSize = 10
      this.loading1 = true
      const { data: result } = await this.$axios.get('/fireHydrant/list', { params: data })
      if (result.code === 200) {
        this.loading1 = false
        this.waterList = result.data.result
        this.total = result.data.total
      } else {
        this.loading1 = false
        this.$message.error(result.msg)
      }
    },
    // 添加水源
    addWater () {
      this.$refs.waterForm.validate(async valid => {
        if (valid) {
          if (this.water.areaCodes.length > 0) {
            this.water.areaCode = this.water.areaCodes[this.water.areaCodes.length - 1]
          } else {
            this.$message.error('请选择该机构所在行政区域')
            return
          }
          if (!this.water.lng || !this.water.lat) {
            this.$message.error('请选择该水源的经纬度')
            return
          }
          if (!this.water.imageUrl) {
            this.$message.error('请上传一张该水源的图片')
            return
          }
          const { data: result } = await this.$axios.post('/fireHydrant/add', this.water)
          if (result.code === 200) {
            this.$message.success('添加成功')
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.initTable()
            this.waterShow = false
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 删除某条数据
    async delWater (id) {
      const confirm = await this.$confirm('此操作将永久删除该水源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/fireHydrant/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改水源
    updateWater () {
      this.$refs.waterForm.validate(async valid => {
        if (valid) {
          if (this.water.areaCodes.length > 0) {
            this.water.areaCode = this.water.areaCodes[this.water.areaCodes.length - 1]
          } else {
            this.$message.error('请选择该机构所在行政区域')
            return
          }
          if (!this.water.lng || !this.water.lat) {
            this.$message.error('请选择该水源的经纬度')
            return
          }
          if (!this.water.imageUrl) {
            this.$message.error('请上传一张该水源的图片')
            return
          }
          const { data: result } = await this.$axios.put(`/fireHydrant/edit/${this.waterId}`, this.water)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.initTable()
            this.waterShow = false
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 导出Excel
    async exportExcel () {
      this.loading = true
      const result = await this.$axios.post('/util/exportHydrant',{
        keyword:this.keyword,
        bindStatus:this.bindStatus,
        areaCode:this.areaCodes[this.areaCodes.length-1]
      }, { responseType: 'blob' })
      if (result.data.type === 'application/json') {
        this.loading = false
        this.$message.error('数据为空,不能导出')
        return
      }
      if (!result.data.message) {
        this.loading = false
      } else {
        this.loading = false
        this.$message.error(result.data.message)
        return
      }
      const blob = new Blob([result.data], { type: 'application/octet-stream' })
      if ('download' in document.createElement('a')) {
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = '水源信息.xls' // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      } else {
        navigator.msSaveBlob(blob, '水源信息.xls')
      }
    },
    // 查看图片
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getWaterImage (res, file, fileList) {
      if (res.code === 200) {
        this.water.imageUrl = res.data
      }
    },
    error (err, file, fileList) {
      this.$message.error(err)
    },
    // 获取位置
    async getPosition () {
      this.water.lng = this.location.lng
      this.water.lat = this.location.lat
      this.mapShow = false
    },
    // 设置位置
    async setPosition (e) {
      this.location.lng = e.point.lng
      this.location.lat = e.point.lat
    },
    // 地图初始化
    handler ({ BMap, map }) {
      var geolocation = new BMap.Geolocation()
      geolocation.getCurrentPosition((r) => {
        this.location.lng = r.point.lng
        this.location.lat = r.point.lat
      })
    },
    async open (flag, row) {
      switch (flag) {
        case 'add':
          this.optFlag = true
          this.waterShow = true
          this.$nextTick(() => {
            this.$refs.waterForm.clearValidate()
          })
          break
        case 'edit':
          this.optFlag = false
          this.waterId = row.id
          if (!row.imageUrl) {
          } else { this.fileList = [{ name: 'file', url: row.imageUrl }] }
          this.water = JSON.parse(JSON.stringify(row))
          this.waterShow = true
          break
        case 'map':
          this.mapShow = true
          break
        case 'location':
          this.mapShow = true
          this.location = row
          break
        case 'bind':
          this.waterId = row.id
          this.getDevice()
          this.bindShow = true
          break
        case 'info': {
          this.deviceLoading = true
          this.infoShow = true
          const { data: result } = await this.$axios.get('/equipment/searchEquipmentByEqCode', { params: { equipmentCode: row.equipmentId } })
          if (result.code === 200) {
            this.device = result.data
          } else {
            this.device = {}
          }
          this.deviceLoading = false
        }
          break
      }
    },
    // 查找建筑结构
    async getFireUnitList () {
      const { data: result } = await this.$axios.get('/organization/list', { params: { parentId: 1 } })
      if (result.code === 200) {
        this.fireUnitList = result.data
      }
    },
    // 查找水源类型
    async getWaterTypeList () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'waterType' } })
      if (result.code === 200) {
        this.waterTypeList = result.data
      }
    },
    // 查找设备
    async getDevice () {
      const data = {
        equipmentCode: this.equipmentCode,
        typeId: 3,
        pageSize: 10,
        pageNo: this.devicePage
      }
      const { data: result } = await this.$axios.get('/equipment/list', { params: data })
      if (result.code === 200) {
        this.deviceList = result.data.result
        this.deviceTotal = result.data.total
      }
    },
    // 查找行政区域
    async getAreaList () {
      const { data: result } = await this.$axios.get('/area/listByUser')
      if (result.code === 200) {
        if (result.data.length === 0) {
          this.isLoad = true
          return
        }
        this.areaList = this.changeKey(result.data)
      } else {
        this.$message.error(result.msg)
      }
    },
    changeKey (array) {
      array.forEach((item, index) => {
        [item.value, item.areaCode] = [item.areaCode, item.value]
        if (item.children) {
          item.children.forEach((subItem, index2) => {
            [subItem.value, subItem.areaCode] = [subItem.areaCode, subItem.value]
            if (subItem.children) {
              subItem.children.forEach((subItem2, index) => {
                [subItem2.value, subItem2.areaCode] = [subItem2.areaCode, subItem2.value]
              })
            }
          })
        }
      })
      return array
    },
    // 绑定设备
    async bindDevice () {
      const { data: result } = await this.$axios.post('/fireHydrant/bindEquipment', { equipmentId: this.checkEquipmentCode, id: this.waterId })
      if (result.code === 200) {
        this.bindShow = false
        this.initTable()
        this.$message.success('绑定成功')
      } else {
        this.$message.error(result.msg)
      }
    },
    // 解除绑定
    async unbind (id) {
      const confirm = await this.$confirm('此操作将解除绑定, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.put(`/fireHydrant/unBindEquipment/${id}`)
        if (result.code === 200) {
          this.bindShow = false
          this.initTable()
          this.$message.success('解绑成功')
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    async remoteMethod (query) {
      const { data: result } = await this.$axios.get('/unit/listAllUnit', { params: { unitName: query } })
      if (result.code === 200) {
        this.unitOptions = result.data
      }
    },
    // 选择NB水压
    chooseRow (currentRow, oldCurrentRow) {
      this.checkEquipmentCode = currentRow.equipmentCode
    },
    // 关闭抽屉
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => { })
    },
    // 关闭弹窗清空表单
    clear () {
      this.water = JSON.parse(this.waterCopy)
      this.$refs.upload.clearFiles()
      this.$refs.waterForm.clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.map {
  width: 100%;
  height: 500px !important;
  .bmView {
    height: 100% !important;
    > div:first-child {
      height: 500px !important;
    }
  }
}
.demo-table-expand {
  font-size: 0;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
    }
  }
}
::v-deep  .el-table__expanded-cell {
  padding: 0 50px;
}
.name {
  text-overflow: ellipsis;
  overflow: hidden;
}
.addr {
  font-size: 12px;
  color: #b4b4b4;
}
.drawer-form {
  font-size: 0;
  padding: 10px 40px;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
      float: left;
    }
    ::v-deep .el-form-item__content {
      float: left;
    }
  }
}
::v-deep  :focus {
  outline: 0;
}
</style>
